@use 'sass:color';

$black: #1e1e1e;
$black-5: color.scale($black, $lightness: 95%);
$black-10: color.scale($black, $lightness: 90%);
$black-20: color.scale($black, $lightness: 80%);
$black-30: color.scale($black, $lightness: 70%);
$black-40: color.scale($black, $lightness: 60%);
$black-50: color.scale($black, $lightness: 50%);
$black-60: color.scale($black, $lightness: 40%);
$black-80: color.scale($black, $lightness: 20%);
$black-90: color.scale($black, $lightness: 10%);
$black-95: color.scale($black, $lightness: 5%);
$black-10-alpha: rgba($black, 0.1);
$navbar-background: #ffffff;
$blue: #00b1da; // RevRoad Blue
$blue-2: #07a5f4; // Bootstrapped Bluebird
$blue-3: #0278de; // Cap Table Cobalt
$blue-dark: #366AA6;
$blue-light: #00b2daa0;
$blue-light-2: #c9eff8;
$blue-light-3: #f5fdfe;
$blue-gray: #eaf2f4;
$linkedin-blue: #025D8F;
// $green: #00d5a7; // Services Seafoam
// $green: #56982B;
$green: #74C242;
$red: #ef3e3a; // Venture Vermilion
$red-dark: #CA1C1C;
$rev-gray: #e9e7e8; // Capital Cloud
$rev-gray-2: #d8d9d8;
$simply-gray: #f5f5f5;
$simply-gray-2: #ebeeed;
$gray1: #c0c0c0;
$gray1-dark: darken($gray1, 10%);
$gray2: #767576;
$gray2-dark: darken($gray2, 10%);
$gray3: #b0b0b0;
$gray3-dark: darken($gray3, 10%);
$citron: #f1fdd3;
$darker-citron: #dff4ab;
$light-blue: #e9f5f7;
$lighter-blue: #f5fdfe;
$medium-blue: #9cddeb;
$glow: #fcc740;
$lighter-glow: #fbedca;
$sun: #ffeb40;
//$black: #202020;
//$black: #212529;
// $black: #212121; // Chief Charcoal
$pure-black: #000000;
$light-black: #3e3e3e; //Shareholder Shadow
$brown: #D16015;
$purple: #9A39A5;
$purple-light: #DEAEE3;
$orange: #ECAB04;
$yellow: $sun;
$navbar-link: #212121;
$navbar-link-hover: #00b1da;
$body-background: $black-5;
$badge-red: #FDD0D0;
$badge-yellow: #F8EEAF;
// $btn-primary-disabled: color.scale($btn-primary, $lightness: 70%);

// Google sign-in button (to mimic for the Microsoft button)
$google-border-gray: #dadce0;
$google-text-gray: #3c4043;
$google-hover-blue: #f8fafe;
$google-hover-outline-blue: #d2e2fc;

//breakpoints
$small: 576px;
$medium: 768px;
$large: 992px;
$x-large: 1200px;
$xx-large: 1400px;

$body-font: 'Telegraf', sans-serif;
$header-font: 'Telegraf', sans-serif;

// Pioneer Fonts //PIONEER-DEMO
// $body-font: 'Barlow', sans-serif;
// $header-font: 'Barlow', sans-serif;

// $form-switch-width: 2.25em;

// Bootstrap overrides
$primary: $black;
$info: $blue;
// $dark: $black;
// $body-tertiary-bg: $black-5;
$body-color: $black;
$link-decoration: none;
$input-placeholder-color: $black-50;
$navbar-toggler-focus-width: 0;
// $form-select-focus-box-shadow: none;
// $dropdown-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.1);
$enable-shadows: true;
$box-shadow: 0px 1px 20px 0px $black-10-alpha;
$focus-ring-width: 1px;
$focus-ring-opacity: 1;
$focus-ring-color: $medium-blue;
// $focus-ring-box-shadow: 0 0 $focus-ring-blur $focus-ring-width $focus-ring-color !default;
$border-color: #e9eceb;
$border-color-translucent: $black-20;
$input-border-color: $black-20;
$form-check-input-border: var(--bs-border-width) solid $input-border-color;
$input-focus-border-color: $medium-blue;
// $input-focus-border-color: rgba(0, 0, 0, 1);
// $input-focus-width: 2px;
$input-padding-y: 0.6875rem;
$input-padding-x: 1.125rem;
$input-padding-y-sm: 0.375rem;
$input-padding-x-sm: 1rem;
$input-font-size: 1rem;
$input-font-size-sm: 1rem;
$input-font-size-lg: 1rem;
// $input-border-radius: 1rem;
$input-border-radius-sm: 200px;
// $input-focus-box-shadow: none;
$input-btn-font-size: 0.875rem;
$input-btn-font-size-lg: 0.875rem;
$btn-font-weight: 600;
$btn-border-width: 1px;
$btn-border-radius: 200px;
$btn-border-radius-lg: 200px;
$input-btn-padding-y: 0.5625rem;
$input-btn-padding-x: 1.1875rem;
$input-btn-padding-y-lg: 0.9375rem;
$input-btn-padding-x-lg: 2rem;
$btn-line-height: 1.2;
$btn-box-shadow: none;
$btn-link-color: $blue;
$link-color: $blue;
$form-label-margin-bottom: 0.25rem;
$form-label-font-size: 0.875rem;
$form-label-font-weight: 600;
$form-label-color: $black-80;
// $form-select-padding-y: 0.75rem;
// $form-select-padding-x: 1.5rem;
$form-select-indicator-padding: 2.5rem;
$form-select-indicator-color: #808080;
$form-select-indicator: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#{$form-select-indicator-color}" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M8 2v9M4 8l4 4 4-4"/></svg>');
$form-select-indicator-invalid: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#{$red}" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M8 2v9M4 8l4 4 4-4"/></svg>');
$file-drop-zone-border: url('data:image/svg+xml,<svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg"><rect width="100%" height="100%" fill="none" rx="16" ry="16" stroke="#{$black-20}" stroke-width="2" stroke-dasharray="12, 8" stroke-dashoffset="0" stroke-linecap="square"/></svg>');
$file-drop-zone-border-invalid: url('data:image/svg+xml,<svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg"><rect width="100%" height="100%" fill="none" rx="16" ry="16" stroke="#{$red}" stroke-width="2" stroke-dasharray="12, 8" stroke-dashoffset="0" stroke-linecap="square"/></svg>');
$form-select-bg-size: 20px;
$form-select-bg-position: right 0.75rem center;
// $form-select-border-radius-sm: 1rem;
// $form-select-padding-y-sm:
// $form-select-padding-x-sm: 1rem;
$border-radius: 1rem;
$border-radius-lg: 1rem;
$border-radius-xxl: 2rem;
$dropdown-border-color: $black-20;
$dropdown-link-active-bg: $black-20;
$dropdown-link-active-color: $black;
// $dropdown-link-active-color: $black;
$component-active-bg: $blue;
$form-switch-width: 2.5em;
$form-text-color: $black-80;
$badge-font-size: 0.875rem;
$badge-font-weight: 400;
$badge-color: $black;
$badge-padding-y: 0.21875rem;
$badge-padding-x: 0.6875rem;
$badge-border-radius: 100rem;
$modal-content-bg: $body-background;
$modal-header-padding: 1.5rem 1.5rem 0 1.5rem;
$modal-header-border-width: 0;
$modal-inner-padding: 1.5rem;
$modal-title-line-height: 1;
$nav-link-font-size: 0.875rem;
$nav-link-font-weight: 500;
// $nav-link-padding-x: 0;
$navbar-nav-link-padding-x: 0;
// $nav-link-hover-color: black;