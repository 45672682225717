// Adapted from https://codepen.io/garetmckinley/pen/YmxYZr
// Garet McKinley
// August 2, 2019

$toggle-background-color-on:$blue;
$toggle-background-color-off: darkgray;
$toggle-control-color: white;
$toggle-width: 40px;
$toggle-height: 20px;
$toggle-gutter: 4px;
$toggle-radius: 50%;
$toggle-control-speed: .15s;
$toggle-control-ease: ease-in;

$toggle-radius: calc($toggle-height / 2);
$toggle-control-size: $toggle-height - ($toggle-gutter * 2);

.toggle-control {
    display: block;
    position: relative;
    padding-left: $toggle-width;
    margin-bottom: 20px;
    cursor: pointer;
    font-size: 22px;
    user-select: none;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    input:checked~.control {
        background-color: $toggle-background-color-on;

        &:after {
            left: $toggle-width - $toggle-control-size - $toggle-gutter;
        }
    }

    .control {
        position: absolute;
        top: 0;
        left: 0;
        height: $toggle-height;
        width: $toggle-width;
        border-radius: $toggle-radius;
        background-color: $toggle-background-color-off;
        transition: background-color $toggle-control-speed $toggle-control-ease;

        &:after {
            content: "";
            position: absolute;
            left: $toggle-gutter;
            top: $toggle-gutter;
            width: $toggle-control-size;
            height: $toggle-control-size;
            border-radius: $toggle-radius;
            background: $toggle-control-color;
            transition: left $toggle-control-speed $toggle-control-ease;
        }
    }
}
