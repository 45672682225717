@font-face {
  font-family: Telegraf;
  src: url('https://cdn.revroad.com/fonts/PPTelegraf-Ultralight.woff2')
    format('woff2');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: Telegraf;
  src: url('https://cdn.revroad.com/fonts/PPTelegraf-UltralightSlanted.woff2')
    format('woff2');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: Telegraf;
  src: url('https://cdn.revroad.com/fonts/PPTelegraf-Light.woff2')
    format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Telegraf;
  src: url('https://cdn.revroad.com/fonts/PPTelegraf-LightSlanted.woff2')
    format('woff2');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: Telegraf;
  src: url('https://cdn.revroad.com/fonts/PPTelegraf-Regular.woff2')
    format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Telegraf;
  src: url('https://cdn.revroad.com/fonts/PPTelegraf-RegularSlanted.woff2')
    format('woff2');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: Telegraf;
  src: url('https://cdn.revroad.com/fonts/PPTelegraf-Medium.woff2')
    format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Telegraf;
  src: url('https://cdn.revroad.com/fonts/PPTelegraf-MediumSlanted.woff2')
    format('woff2');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: Telegraf;
  src: url('https://cdn.revroad.com/fonts/PPTelegraf-Semibold.woff2')
    format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: Telegraf;
  src: url('https://cdn.revroad.com/fonts/PPTelegraf-SemiboldSlanted.woff2')
    format('woff2');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: Telegraf;
  src: url('https://cdn.revroad.com/fonts/PPTelegraf-Bold.woff2')
    format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Telegraf;
  src: url('https://cdn.revroad.com/fonts/PPTelegraf-BoldSlanted.woff2')
    format('woff2');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: Telegraf;
  src: url('https://cdn.revroad.com/fonts/PPTelegraf-Ultrabold.woff2')
    format('woff2');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: Telegraf;
  src: url('https://cdn.revroad.com/fonts/PPTelegraf-UltraboldSlanted.woff2')
    format('woff2');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: Telegraf;
  src: url('https://cdn.revroad.com/fonts/PPTelegraf-Black.woff2')
    format('woff2');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: Telegraf;
  src: url('https://cdn.revroad.com/fonts/PPTelegraf-BlackSlanted.woff2')
    format('woff2');
  font-weight: 900;
  font-style: italic;
}
