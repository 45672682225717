@import 'variables';
@import 'fonts';
@import 'toggle';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.fs-10 {
  font-size: 0.625rem !important;
}

.fs-12 {
  font-size: 0.75rem !important;
}

.fs-14 {
  font-size: 0.875rem !important;
}

.fs-16 {
  font-size: 1rem !important;
}

.fs-18 {
  font-size: 1.125rem !important;
}

.fs-20 {
  font-size: 1.25rem !important;
}

.fs-22 {
  font-size: 1.375rem !important;
}

.fs-24 {
  font-size: 1.5rem !important;
}

.fs-26 {
  font-size: 1.625rem !important;
}

.fs-32 {
  font-size: 2rem !important;
}

.fs-52 {
  font-size: 3.25rem !important;
}

.lh-1-2 {
  line-height: 1.2 !important;
}

.swal-text {
  text-align: center;
}

body {
  font-weight: 400;
  background-color: $body-background;
  font-family: $body-font;
}

body.with-footer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  & > .container {
    flex-grow: 1;
  }
}

.container {
  // all containers
  max-width: 1320px;
  @media (min-width: $small) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

body > .container {
  // just the top-level container
  // mimic the container padding
  margin-top: calc($container-padding-x * 0.5);
  margin-bottom: calc($container-padding-x * 0.5);
  @media (min-width: $small) {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
}

// generally, more-container-padding is for non-hub pages
body.more-container-padding .container {
  // all containers
  @media (min-width: $small) {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}

body.more-container-padding > .container {
  // just the top-level container
  @media (min-width: $small) {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
}

$navbar-height-sm: 76px;
$navbar-height-lg: 76px;

.navbar {
  background-color: $navbar-background;
  font-size: 16px;
  font-weight: 400;
  height: 76px;
  padding: 0px 20px;
  border-bottom: 1px solid $border-color;

  .navbar-brand {
    padding: 0px;

    img {
      height: 36px;
    }
  }
}

.navbar-nav.navbar-nav-left {
  column-gap: 2rem;
}

.navbar-nav {
  column-gap: 1.5rem;
  .dropdown-menu {
    // padding: 0;
    min-width: 8.0625rem;
  }
  .dropdown-item {
    font-size: 0.875rem;
    font-weight: 500;
    padding: 1rem 1.5rem;
    line-height: 1.25rem;
    text-transform: uppercase;
    &:hover {
      color: $blue;
      background-color: $light-blue;
    }
  }
}

.navbar-nav .nav-link {
  color: $navbar-link;
  padding-left: 0px;
  padding-right: 0px;
  text-transform: uppercase;

  &.active {
    color: $blue;
  }
}

.navbar-light .navbar-toggler {
  color: $black;
  border: none;
  padding: 0 3px;
}

#mobile-nav {
  transform: translateY(-100%);
  transition: transform 0.3s ease;
  position: absolute;
  top: 76px;
  width: 100%;
  z-index: -1;

  &.show {
    top: 76px;
    transform: translateY(0px);
  }
}

.mobile-nav {
  background-color: white;
  padding: 1.5rem;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  border-bottom: 1px solid #eff0f6;

  .nav-link {
    margin-bottom: .75rem;
  }

  .navbar-nav.navbar-nav-left .nav-link {
    padding: .75rem 0;
    line-height: 20px;
  }

  @media (min-width: $large) {
    display: none;
  }
}

// .nav-link:focus,
// .nav-link:hover {
//   color: $navbar-link-hover !important;
// }

.hamburger-menu-bar {
  border-radius: 20px;
  margin-top: 12px;
  margin-bottom: 12px;
  background-color: $black;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 2px;
  display: flex;
  transition: transform 0.3s ease;
  transform-style: preserve-3d;
  &.top {
    transform: translate3d(0px, 8px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(135deg) skew(0deg, 0deg);
  }
  &.bottom {
    transform: translate3d(0px, -7px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(45deg) skew(0deg, 0deg);
  }
}

.navbar-toggler.collapsed .hamburger-menu-bar {
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
}

.link {
  color: $blue;
  cursor: pointer;

  &:hover,
  &:focus {
    color: $blue-dark;
    text-decoration: none;
  }
}

// Print Styles

a.print-header:link {
  text-decoration: none;
  color: #ffffff !important;
}

a.print-header:visited {
  text-decoration: none;
  color: #ffffff !important;
}

.print-header {
  @media print {
    border-top: 0px !important;
    font-size: 1.75rem !important;
  }
}

// Utility Classes
.mb-header {
  margin-bottom: 2.25rem !important;
}

.mt-n2 {
  margin-top: -.5rem !important;
}

.mt-n3-5 {
  margin-top: -1.25rem !important;
}

.mb-2-5 {
  margin-bottom: 0.75rem !important;
}

.mb-4-3 {
  margin-bottom: 2rem !important;
}

.mb-4-5 {
  margin-bottom: 2.25rem !important;
}

.mt-sm-6 {
  @media (min-width: $small) {
    margin-top: 6rem !important;
  }
}

.mt-md-6 {
  @media (min-width: $medium) {
    margin-top: 6rem !important;
  }
}

.mt-lg-6 {
  @media (min-width: $large) {
    margin-top: 6rem !important;
  }
}

.mt-6 {
  margin-top: 6rem !important;
}

.mb-6 {
  margin-bottom: 6rem !important;
}

.pt-6 {
  padding-top: 6rem !important;
}

.pb-6 {
  padding-bottom: 6rem !important;
}

.px-2-5 {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}

.px-75 {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}

.clear-anchor-text {
  color: initial !important;

  &:hover,
  &:focus {
    color: initial !important;
  }
}

[aria-disabled="true"] {
  opacity: 0.3;
}

.pointer {
  cursor: pointer;
}

.disabled-button {
  opacity: 0.5;
  cursor: default;
}

.hover-container:hover .hide {
  display: inline;
}

.hide {
  display: none;
}

.directory-subtitle {
  font-size: 16px;
  font-weight: 400;
}

.tooltip-title {
  font-size: 20px;
  font-weight: 300;
}

.carousel-indicators {
  position: static;

  li {
    width: 10px !important;
    height: 10px !important;
    border-radius: 10px !important;
    margin-right: 5px !important;
    margin-left: 5px !important;
    cursor: pointer !important;
    background-color: $gray2 !important;
    border-top: 0 !important;
    border-bottom: 0 !important;
  }
}

.text-success {
  color: $green !important;
}

.border-success {
  border-color: $green !important;
}

.border-blue {
  border-color: $blue !important;
}

.btn {
  text-transform: uppercase;
  &.disabled {
    background-color: $black-20 !important;
    color: $black-90;
    border: none;
  }
}

.btn-company-nav {
  min-width: 110px;

  @media (min-width: $small) {
    min-width: 158px;
  }
}

.no-grow {
  transform: none !important;
}

.grouped-btn {
  border-radius: 0rem;
}

.btn-sm {
  padding: 0.25rem 0.75rem;
  font-size: 13px !important;
  line-height: 1.5 !important;
  border-radius: 1px;
  min-width: initial !important;
}

.btn-outline-blue {
  border-color: $blue;
  border-width: 1px;
  color: $blue;

  &:hover,
  &:focus,
  &:not(:disabled):not(.disabled):active {
    background-color: $blue;
    border-color: transparent;
    color: white;
  }
  &:not(:disabled):not(.disabled).active {
    background-color: $blue;
    border-color: transparent;
    color: white;
  }

  &.faded {
    opacity: 0.8;
  }
}

.btn-jumbo {
  height: 60px;
  font-size: 17px;
  padding: 22px 30px 21px;
}

.btn-primary {
  --bs-btn-hover-bg: #{$black-90};
}

.btn-outline-primary {
  --bs-btn-hover-bg: #{$black-10};
  --bs-btn-hover-color: $black;
  --bs-btn-active-bg: #{$black-10};
  --bs-btn-active-color: $black;
}

.btn-success {
  background-color: $blue;
  border-color: $blue;
  color: white;

  &:hover,
  &:focus,
  &:not(:disabled):not(.disabled):active {
    background-color: $blue-dark;
    border-color: transparent;
    color: white;
  }

  &.faded {
    opacity: 0.8;
  }
}

.btn-outline-secondary {
  border-color: $gray2;
  color: $gray2;
  border-width: 1px;

  &:hover,
  &:focus,
  &:not(:disabled):not(.disabled):active {
    background-color: $gray2;
    border-color: transparent;
  }
  &:not(:disabled):not(.disabled).active {
    background-color: $gray2;
    border-color: transparent;
  }

  &.faded {
    opacity: 0.8;
  }
}

.btn-secondary {
  background-color: $blue-gray;
  color: $blue;
  &:hover,
  &:focus,
  &:not(:disabled):not(.disabled):active {
    background-color: darken($blue-gray, 10%);
    border-color: transparent;
  }

  &.faded {
    opacity: 0.8;
  }
}


.btn-ecomp {
  font-size: 18px;
  font-weight: 300 !important;
  text-transform: none;
  padding: 14px 40px 12px;
}

.btn-fixed {
  width: 100%;
  color: $gray3;
  text-transform: none;
  font-family: source-sans-3, sans-serif;
  font-weight: 400 !important;
}

.badge {
  background-color: $light-blue;
  border: 1px solid $light-blue;
}

// Solid Badges
.badge-green {
  background-color: $darker-citron;
  border: 1px solid $darker-citron;
}

.badge-yellow {
  background-color: $badge-yellow;
  border: 1px solid $badge-yellow;
}

.badge-red {
  background-color: $badge-red;
  border: 1px solid $badge-red;
}

.badge-blue {
  background-color: $medium-blue;
  border: 1px solid $medium-blue;
}

.badge-light-blue {
  background-color: $light-blue;
  border: 1px solid $light-blue;
}

.badge-purple {
  background-color: $purple-light;
  border: 1px solid $purple-light;
}

.badge-citron {
  background-color: $citron;
  border: 1px solid $citron;
}

// Outline Badges
.badge-outline-blue {
  background-color: white;
  border: 1px solid $blue;
  color: $blue;
}

.badge-outline-green {
  background-color: white;
  border: 1px solid $green;
  color: $green;
}

.badge-outline-red {
  background-color: white;
  border: 1px solid $red;
  color: $red;
}

.badge-outline-white {
  background-color: $black;
  border: 1px solid white;
  color: white;
}

.badge-outline-gray {
  background-color: white;
  border: 1px solid $black-60;
  color: $black-60 !important;
}

.badge-outline-purple {
  background-color: white;
  border: 1px solid $purple;
  color: $purple;
}

.btn-progress-report {
  background-color: $simply-gray;
  border-color: transparent;
  border-radius: 30px;
  height: 35px;
  padding: 0 1rem;
  color: #54575b;
  font-weight: 300;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}

.btn-crunchbase {
  background-color: #0188d0;

  &:hover,
  &:focus,
  &:not(:disabled):not(.disabled):active {
    background-color: #01486e;
    border-color: transparent;
    color: white;
  }

  &.faded {
    opacity: 0.8;
  }
}

.btn-g2 {
  background-color: #ff492c;

  &:hover,
  &:focus,
  &:not(:disabled):not(.disabled):active {
    background-color: darken(#ff492c, 10%);
    border-color: transparent;
    color: white;
  }

  &.faded {
    opacity: 0.8;
  }
}

.btn-yahoo {
  background-color: #6425c1;

  &:hover,
  &:focus,
  &:not(:disabled):not(.disabled):active {
    background-color: #3d1974;
    border-color: transparent;
    color: white;
  }

  &.faded {
    opacity: 0.8;
  }
}

.btn-pitchbook {
  background-color: #dddddd;

  &:hover,
  &:focus,
  &:not(:disabled):not(.disabled):active {
    background-color: #818181;
    border-color: transparent;
    color: white;
  }

  &.faded {
    opacity: 0.8;
  }
}

.btn-light {
  background-color: $gray1;

  &:hover,
  &:focus {
    background-color: $gray1-dark;
    border-color: transparent;
  }
}

.btn-white {
  border-color: white;
  background-color: white;
  color: $blue;

  &:hover,
  &:focus {
    background-color: $rev-gray-2;
    border-color: transparent;
  }
}

.btn-cancel {
  // background-color: white;
  // color: $black;
  border: 1px solid $black-20;

  &:hover,
  &:focus,
  &:active,
  &:first-child:active {
    background-color: $black-10;
    border-color: $black-20;
    color: $black;
  }
  &:disabled {
    border-color: $black-20;
  }
}

.btn-outline-white {
  border-color: white;
  color: white;

  &:hover,
  &:focus {
    background-color: white;
    border-color: $black-20;
    color: $black;
  }
}

.btn-score {
  min-width: 0px;
  padding: 0.25rem 1rem;
}

.btn-small {
  height: 30px;
  min-width: 0px;
  padding: 0rem 1.2rem;
}

.btn-danger {
  color: white;
  background-color: $red;

  &:hover,
  &:focus {
    color: white;
    background-color: $red-dark;
    border-color: transparent;
  }
}

.btn-medium {
  padding: 0.5rem 1.5rem;
}

.btn-sm-width {
  min-width: 80px !important;
}

.btn-sm-set-width {
  width: 126px !important;
}

// .btn-header {
//   min-width: initial;
//   padding: 14px 20px 12px;
// }

.btn-social-sign-in {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 230px;
  height: 40px;
  background-color: white;
  border-style: solid;
  border-color: $google-border-gray;
  border-width: 1px;
  color: $google-text-gray;
  border-radius: 4px;
  text-decoration: none !important;

  img {
    margin-right: 8px;
    width: 18px;
  }
}

.btn-social-sign-in:hover {
  background-color: $google-hover-blue;
  text-decoration: none !important;
  color: $google-text-gray;
  transition: background-color 0.25s ease-in-out, border-color 0.25s ease-in-out;
  border-color: $google-hover-outline-blue;
}

.btn-microsoft {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.25px;
}

.judge-name {
  margin: 0;
  font-weight: 600;
  color: $navbar-link-hover;
  font-size: 16px;
}

.judge-company {
  margin: 0;
  color: $light-black;
}

.judge-title {
  margin: 0;
  color: $light-black;
  opacity: 0.7;
  font-style: italic;
}

.collapsible-icon {
  cursor: pointer;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-weight: 600;
  font-size: 24px;
  background-color: #ffffff;

  &.company {
    font-weight: 300 !important;
    font-size: 20px;
  }
}

.collapsible-icon:after {
  content: url(https://s3.us-west-2.amazonaws.com/dashboard.revroad.com/images/collapsible-icon-before.svg);
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-schedule a[href^='mailto:'] {
  color: $light-black;
}

.card-schedule a[href^='mailto:']:hover {
  color: $google-hover-blue;
}

.collapsible-active:after {
  content: url(https://s3.us-west-2.amazonaws.com/dashboard.revroad.com/images/collapsible-icon-after.svg);
}

.text-justify {
  text-align: justify;
}

.time-slot {
  font-size: 18px;
  font-weight: 400;
  margin-right: auto;
}

.company-name {
  font-size: 24px;
  font-weight: 100;

  &.list {
    margin-right: auto;
    padding-left: 15px;
    text-align: left;
    display: inline-block;
  }
}

.company-schedule {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid $gray1;
}

.btn-signin-email {
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.25px;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.cancel-input {
  float: right;
  margin-right: 10px;
  margin-top: -30px;
  position: relative;
  z-index: 2;
  color: $gray2;
}

.apply-title {
  font-size: 1.5rem;
  font-weight: 900;
  margin: 0;

  @media (min-width: $small) {
    font-size: 1.75rem;
  }

  @media (min-width: $medium) {
    font-size: 2rem;
  }
}

.circle-stack {
  font-size: 1.1875rem;
}

.circle-check {
  font-size: 0.55rem !important;
}

.clear-accordian {
  background-color: $body-background !important;
  outline: 0 !important;
  color: $light-black !important;
  box-shadow: none !important;
}

.alert-form {
  margin: 0 0 10px;
}

.errorlist {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.desktop {
  display: none;
}

.background-color-simply-gray {
  background-color: $simply-gray !important;
}

.background-color-blue-light {
  background-color: $blue-light-3 !important;
}

.background-color-FUTURE {
  background-color: $green;
}

.background-color-STARTING {
  background-color: $blue-dark;
}

.background-color-EXITED {
  background-color: $green;
}

.background-color-DEFUNCT {
  background-color: $red;
}

.background-color-GRADUATED {
  background-color: $purple;
}

.background-color-ACCEPTED {
  background-color: $blue;
}

.background-color-blue {
  background-color: $blue;
}

.background-color-blue-dark {
  background-color: $blue-dark !important;
}

.bg-black {
  background-color: $black !important;
}

.bg-darker-citron {
  background-color: $darker-citron !important;
}

.bg-citron {
  background-color: $citron !important;
}

.bg-dark {
  background-color: $black !important;
}

.bg-gray {
  background-color: $gray2 !important;
}

.bg-light-blue {
  background-color: $light-blue !important;
}

.bg-lighter-blue {
  background-color: $lighter-blue !important;
}

.bg-lighter-glow {
  background-color: $lighter-glow !important;
}

.bg-glow {
  background-color: $glow !important;
}

.bg-medium-blue {
  background-color: $medium-blue !important;
}

.bg-orange {
  background-color: $orange !important;
}

.bg-red {
  background-color: $red !important;
}

.bg-success {
  background-color: $blue !important;
}

.color-black {
  color: $black !important;
}

.color-black-50 {
  color: $black-50 !important;
}

.color-black-30 {
  color: $black-30 !important;
}

.color-black-80 {
  color: $black-80 !important;
}

.color-blue {
  color: $blue !important;
}

.color-blue-dark {
  color: $blue-dark !important;
}

.color-blue-light {
  color: lighten($blue, 50%) !important;
}

.color-brown {
  color: $brown !important;
}

.color-glow {
  color: $glow !important;
}

.color-gray1 {
  color: $gray1 !important;
}

.color-gray1-dark {
  color: $gray1-dark !important;
}

.color-gray2 {
  color: $gray2 !important;
}

.color-gray3 {
  color: $gray3 !important;
}

.color-green {
  color: $green !important;
}

.color-light-black {
  color: $light-black !important;
}

.color-orange {
  color: $orange !important;
}

.color-placeholder {
  color: $black-50;
}

.color-purple {
  color: $purple !important;
}

.color-red {
  color: $red !important;
}

.color-rev-gray {
  color: $rev-gray !important;
}

.color-sun {
  color: $sun !important;
}

.color-white {
  color: white !important;
}

.color-white-60 {
  color: #ffffff99 !important;
}

.color-FUTURE,
.color-EXITED,
.color-success {
  color: $green;
  border-color: $green;
}

.color-STARTING {
  color: $blue-dark;
  border-color: $blue-dark;
}

.color-DEFUNCT,
.color-danger {
  color: $red;
  border-color: $red;
}

.color-GRADUATED {
  color: $purple;
  border-color: $purple;
}

.color-ACCEPTED {
  color: $blue;
  border-color: $blue;
}

.color-blue {
  color: $blue;
  border-color: $blue;
}

.color-white {
  color: white;
  border-color: white;
}

.color-gray {
  color: $gray2;
  border-color: $gray2;
}

.edit-hover:hover i {
  color: $gray2 !important;
}

.media-type {
  font-size: 24px;
  color: $blue-2;
}

.circle {
  height: 23px;
  width: 23px;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0px 0px 5px #b8b8b8;
}

.image-shadow {
  border-radius: 10px;
  box-shadow: 0px 0px 5px #b8b8b8;
}

.circle-fixed {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: initial;
  border-color: $black;
  &:hover,
  &:focus {
    border: solid 1px $black !important;
    background-color: $black;
    color: $white;
  }

}

.square-fixed {
  height: 50px;
  width: 50px;
  border-radius: .25rem;
  border-width: 1px;
  border-style: solid;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: initial;
  border-color: $black;
  &:hover,
  &:focus {
    border: solid 1px $black !important;
    background-color: $black;
    color: $white;
  }
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

i,
button {
  &:hover,
  &:focus {
    outline: none;
  }
}

.jumbo-header {
  font-size: 2.5rem;
  font-weight: 800;
  color: $light-black;
  line-height: 1;

  @media (min-width: $small) {
    font-size: 3.5rem;
  }

  @media (min-width: $medium) {
    font-size: 5rem;
  }
}

// Termina Medium
// font-family: $header-font;
// font-weight: 500;
// font-style: normal;

// Termina Bold
// font-family: $header-font;
// font-weight: 700;
// font-style: normal;

h1 {
  color: $black;
  font-size: 32px;
  font-weight: 400;
  font-family: $header-font;

  @media (min-width: $small) {
    font-size: 56px;
  }
  &.active {
    padding-bottom: 4px;
    border-style: solid;
    border-width: 0px 0px 2px 0px;
    border-color: $blue;
  }
  &.inactive {
    font-weight: 300;
  }
  &.small {
    font-weight: 600;
    font-size: 20px;
    font-family: $body-font;
    text-transform: uppercase;
    color: $gray2;
  }
  .header-back {
    color: $gray2;
    font-size: 0.8em;
  }
}

h2 {
  color: $black;
  font-size: 20px;
  font-weight: 300;

  &.marketing {
    font-family: $header-font;
    font-size: 32px;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 0.5rem;
  }

  @media (min-width: $small) {
    font-size: 24px;
  }

  &.extra-bold {
    font-weight: 700;
  }

  &.event-heading {
    font-family: $header-font;
    font-size: 30px;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 0.5rem;
    @media (min-width: $large) {
      font-size: 44px;
    }
  }
}

h3 {
  font-size: 20px;
  font-weight: 600;

  &.marketing {
    font-family: $header-font;
    font-size: 28px;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 0.5rem;
  }
}

h5 {
  color: $black;
  &.marketing {
    font-family: $header-font;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
  }
}
.subheading {
  &.marketing {
    font-family: source-sans-3, sans-serif;
    font-size: 24px;
    font-weight: 300;
  }
}

.subtitle {
  font-size: 20px;
  font-weight: 300;
}

.apply-sub-header {
  font-size: 16px;
  font-weight: 300;

  @media (min-width: $small) {
    font-size: 18px;
  }

  @media (min-width: $medium) {
    font-size: 24px;
  }
}

.footer-image {
  .footer-title {
    font-size: 3rem;
    font-weight: 800;
    line-height: 1;
    color: #fff;
  }
  background-image: url(https://s3.us-west-2.amazonaws.com/dashboard.revroad.com/images/footer-judges-blue.jpg);
  background-position: center center;
  background-size: cover;
}

.location-bubble {
  font-weight: 400;
  border: none;
  border-radius: 10px !important;
  font-style: italic;
}

.volunteer-info {
  border: none;
  border-radius: 20px !important;
  display: inline-block;
  width: auto;
}

.nav-direction {
  border: none;
  border-radius: 10px;
  font-size: 20px;
  font-weight: 600;
}

#submit-vote[disabled] {
  opacity: 0.5;
}

.button-wrapper {
  display: flex;
  justify-content: center;
}

.social-footer {
  background-color: $black;
  font-weight: 400;
  color: white;

  & .social-footer-inner {
    // max-width: 1380px !important;
    // padding: 56px 30px 0px;
    padding-top: 2rem;
    @media (min-width: $medium) {
      padding-top: 3.5rem;
      padding-bottom: 0px;
    }
  }

  a {
    color: white;
  }
  a:hover {
    color: white;
  }
  .footer-social-icons-wrapper {
    font-size: 24px;
  }
}

.copyright-footer {
  color: rgba(122, 122, 122, 0.8);
  font-size: 12px;
  background-color: $simply-gray;
}

.last-updated-text {
  color: #c1c1c1;
  font-size: 14px;
}

// override bootstrap modal animation
.modal.fade .modal-dialog {
  transform: scale(0.9);
}

.modal.fade.show .modal-dialog {
  transform: scale(1);
}

.modal-scroll {
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}

.add-edit-title {
  color: $gray2 !important;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;

  .fa-chevron-left {
    font-size: 12px;
    margin-right: 0.25rem;
  }
}

.quarter {
  color: $gray2;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  text-transform: uppercase;
}

form label:not {
  // margin-bottom: $form-label-margin-bottom;
  @include font-size($form-label-font-size);
  font-style: $form-label-font-style;
  font-weight: $form-label-font-weight;
  color: $form-label-color;
  text-transform: uppercase;
}

.field-label,
// form label,
div.dup-label {
  color: $gray2;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
}

.form-label {
  text-transform: uppercase;
}

.field-label-large {
  color: $gray2;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
}

.field-label-large.not-uppercase {
  text-transform: none;
}

// form label,
div.dup-label {
  margin-bottom: 0.25rem;
}

.founders-label {
  color: $gray2;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
}

.image-wrapper {
  margin: auto;
  text-align: center;
  @media (min-width: $medium) {
    width: 152px;
  }
}

hr {
  &.sign-in {
    border: 0;
    clear: both;
    display: block;
    width: 100%;
    background-color: $gray3;
    height: 1px;
  }
}

.google-square {
  color: $gray2;
  font-size: 7px;
}

.agree-terms {
  color: $gray2;
  font-size: 12px;
  // text-transform: uppercase;
  // a {
  //     text-decoration: underline;
  // }
}

.sponsor-container {
  background-color: $black;
  color: white;
  .sponsor-image-container {
    // width: 210px;
    width: 350px;
    @media (max-width: $small) {
      width: 250px;
    }
  }

  .event-sponsor-image {
    margin: 30px;
    max-height: 120px;
    max-width: 200px;
  }

  .main-event-sponsor-image {
    margin: 30px;
    max-height: 220px;
    max-width: 300px;
  }
}

.ecomp-sponsor-container {
  background-color: $pure-black;
  color: white;

  .ecomp-image-container {
    // width: 210px;
    width: 150px;
    @media (max-width: $small) {
      width: 150px;
    }
  }

  .ecomp-sponsor-image {
    // max-width: 180px;
    // max-height: 75px;
    max-width: 100px;
    max-height: 125px;
    margin: 10px;
    @media (max-width: $small) {
      width: 125px;
    }
  }
}

.ecomp-sponsor-container-page {
  color: white;

  .sponsor-image-container {
    // width: 210px;
    width: 350px;
    @media (max-width: $small) {
      border-top: 1px solid gray;
      width: 250px;
    }
  }

  .sponsor-image {
    // max-width: 180px;
    // max-height: 75px;
    margin: 40px;
    max-width: 250px;
    max-height: 120px;
    // @media (max-width: $small) {
    //   width: 200px;
    // }
  }

  .UVU-special-image {
    margin: 40px;
    max-width: 100px;
    @media (max-width: $small) {
      width: 150px;
    }
  }
}

.homepage {
  .homepage-logo-wrapper {
    // height: 100%;
    width: 32%;
    height: 215px;
    display: flex;
    align-items: center;
    border-radius: 1rem;
  }
  .image-wrapper {
    min-width: 60px;
    width: 100%;
  }

  .logo-initials {
    font-size: 33px;
  }

  .logo-initials-small {
    font-size: 14px;
  }

  .card-body {
    display: flex;
    align-items: center;
  }

  .date {
    color: $gray2;
    font-size: 14px;
    font-weight: 500;
  }

  .logo-container {
    width: 60px !important;
    min-height: 57px !important;
  }

  .homepage-title {
    font-size: 20px;
    font-weight: 300;
  }

  .view-all {
    font-size: 14px;
    color: $blue;
  }
}

.company-row {
  flex-direction: row;
  font-weight: 400;
  width: 100%;

  .logo-container {
    // display: none;
    display: flex;
    align-items: center;
    border-right: 1px solid $simply-gray;
    position: relative;
    max-width: 152px;
    // min-width: 100px;

    @media (max-width: $medium) {
      display: flex;
      width: 100px;
      min-height: 100px;
    }
    // @media (width: 400px) {
    //     display: flex;
    //     width: 100px;
    // }
  }

  .status-flag {
    position: absolute;
    background-color: rgba(176, 176, 176, 0.8);
    top: 0;
    right: 0px;
    color: rgba(255, 255, 255, 0.75);
    font-size: 22px;
    border-bottom-left-radius: 2px;
    width: 34px;
    height: 34px;
  }

  .rank-bubble {
    background-color: $blue;
    border-radius: 50px;
    width: 1.75em;
    height: 1.75em;
    color: white;
    font-size: 0.5em;
    font-weight: 500;
    margin-top: 0.5em;
  }

  .company-title {
    font-weight: 200;
    font-size: 24px;
    line-height: 1.2;

    @media (min-width: $medium) {
      font-size: 30px !important;
    }
  }

  .company-description {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.4;
  }

  .prize-title {
    line-height: 1;
    font-weight: 900;
    font-size: 20px;
    color: rgba(255, 255, 255, 0.6);
    @media (min-width: $medium) {
      font-size: 30px !important;
    }
  }

  .prize-title-sm {
    letter-spacing: 3.25px;
    font-size: 12px !important;
    @media (min-width: $medium) {
      letter-spacing: 4.5px;
      font-size: 18px !important;
    }
  }

  .title-small {
    font-size: 20px !important;
  }

  .amount {
    line-height: 1;
    font-weight: 300;
    font-size: 1rem;
    // color: rgba(255, 255, 255, 0.6);
    @media (min-width: $medium) {
      font-size: 1.5rem !important;
    }
  }

  .company-info {
    margin-top: auto;
    margin-bottom: auto;
  }

  .service-row {
    width: 100%;
    text-align: left;

    @media (min-width: $medium) {
      text-align: right;
    }
  }

  .equity-padding {
    @media (max-width: $large) {
      padding-left: 0 !important;
    }
  }

  .equity {
    color: $gray2;
    font-size: 14px;
    font-weight: 600;

    @media (max-width: $medium) {
      padding-top: 0;
      text-align: center;
      font-size: 12px;
      padding-left: 15px;
    }
  }

  .s {
    color: $gray2;
    font-size: 14px;
    font-weight: 600;
  }

  .card-body {
    padding-bottom: 0px;
    padding-top: 10px;

    @media (max-width: $medium) {
      padding-top: 5px;
    }
  }

  .row {
    width: 100%;
  }
  // .row {
  //     margin-left: -20px;
  //     margin-right: -20px;

  //     @media(max-width: $medium) {
  //         margin-right: -19px;
  //         margin-bottom: 1px;
  //     }
  // }

  .primary-row {
    padding: 12px 20px;
    height: min-content;
  }

  .secondary-row {
    background-color: #f9f9f9;
    padding: 8px 20px;
    min-height: 50px;
  }

  .kpi-green {
    color: $green;
    font-size: 12px;
    font-weight: bold;
  }

  .kpi-red {
    color: $red;
    font-size: 12px;
    font-weight: bold;
  }

  .kpi-gray {
    color: $gray3;
    font-size: 12px;
    font-weight: bold;
  }
}

.resource-row {
  width: 100%;
  flex-direction: row;

  .resource-card-body {
    display: flex;
    width: 100%;
  }

  .resource-image-column {
    width: 100px;
    height: 100%;
    border-right: 1px solid #f9f9f9;
    // @media (max-width: $medium) {
    //     display: none!important;
    // }
  }

  .resource-image-wrapper {
    margin: auto;
  }

  .resource-row {
    width: 100%;

    .resource-row-content {
      margin-right: 0px !important;
      margin-left: 0px !important;
      display: flex;
      width: 100%;
      min-height: 80px;
      padding: 20px 0;
      align-items: center;
    }
  }
}

.resource-title {
  color: #1d2328;
  font-size: 30px;
  font-weight: 300;
  line-height: 28px;
}

.resource-name {
  // font-size: 18px;
  // line-height: 23px;
  font-size: 20px;
  font-weight: 300;
}

.resource-content-text {
  font-size: 14px;
  line-height: 18px;
}

.resource-description {
  color: $gray2;
}

.resource-icon-color {
  color: $gray3;
}

.logo-initials {
  text-align: center;
  font-size: 32px;
  font-weight: 400;
  color: #ffffff;
}

.kpi {
  line-height: 1;
  color: #1d2328;
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 1px;
}

.field-kpi {
  line-height: 21px;
  padding-bottom: 2px;
  color: #1d2328;
  font-size: 16px;
  font-weight: bold;
}

.kpi-large {
  font-size: 20px;
}

.progress {
  height: 7px;
  border-radius: 8px;
  .progress-bar {
    height: 7px;
    border-radius: 8px;
  }
}

.progress-bar-gray {
  background-color: $rev-gray;
  height: 20px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  &.small {
    height: 12px;
  }
}

.progress-bar-blue {
  background-color: $blue;
  height: 100%;
  border-radius: 10px 0 0 10px;
}

.progress-bar-blue-light {
  background-color: $blue-light-2;
  height: 12px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.mini-portrait {
  width: 40px;
  height: 40px;
}

.tag-primary {
  border-radius: 0 0 0 5px;
  padding: 4px 9px 3px;
  font-size: 10px;
  color: white;
  background-color: #00b1da;
  width: min-content;
  line-height: 1;
  font-weight: 400;
  text-transform: uppercase;
  border-color: #00b1da;
  border-style: solid;
  border-width: 1px;
  height: 19px;
}

.tag-float {
  position: absolute !important;
  left: 63px;
  top: -5px;
}

// .pill {
//   border-radius: 10px;
//   padding: 4px 9px 3px;
//   font-size: 10px;
//   color: #ffffff;
//   background-color: $blue;
//   width: min-content;
//   line-height: 1;
//   font-weight: 300;
//   text-transform: uppercase;

//   &.checked {
//     background-color: $blue !important;
//   }
//   &.small {
//     padding: 2px 7px 2px;
//   }
// }

.kpi-change {
  margin-left: 6px;
  font-size: 14px;
  line-height: 1;
  font-weight: bold;
}

.positive {
  color: $green;
}

.no-change {
  color: $gray2;
  font-size: 15px;
}

.no-change-light {
  color: $gray1;
  font-size: 15px;
  font-weight: 300;
}

.negative {
  color: $red;
}

.red {
  color: $red;
}

.add-contact-button {
  font-size: 19px;
  padding-right: 1px;
}

.add-search-button {
  font-size: 19px;
  margin-right: -5px;
}

textarea {
  resize: none;
}

.has-search .form-control {
  padding-left: 2.375rem;
  border: 1px solid $rev-gray-2;
  border-radius: 30px;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  // height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: $gray2;
}

.form-select-custom {
  background-image: none;
  &:hover {
    color: inherit;
  }
  &[disabled] {
    color: $input-disabled-color;
    background-color: $input-disabled-bg;
    border-color: $input-disabled-border-color;
    // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
    opacity: 1;
    cursor: auto;
    &:active,
    &:focus {
      border-color: $input-disabled-border-color !important;
    }
  }
  &.active {
    border-color: $input-focus-border-color;
  }
  &[multiple] {
    padding-right: $form-select-indicator-padding;
  }
  .current-selected-value.is-placeholder {
    color: $input-placeholder-color;
  }
  .badge-count {
    padding: 0.125rem 0.25rem;
    background-color: $darker-citron;
    margin-left: 0.5rem;
  }
}

.form-select-custom::before {
  content: '';
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%) rotateX(0deg);
  width: 20px;
  height: 20px;
  background-image: #{escape-svg($form-select-indicator)};
  background-size: contain;
  background-repeat: no-repeat;
  transition: transform 0.5s ease;
}

.form-select-custom.is-invalid::before {
  background-image: #{escape-svg($form-select-indicator-invalid)};
}

.form-select-custom.show::before {
  transform: translateY(-62%) rotateX(180deg);
}

.form-select-dark {
  border: 1px solid $rev-gray-2;
  border-radius: 2rem;
  padding: 0.375rem 2.25rem 0.375rem 1rem;
}

.equity-input {
  padding-left: 10px;
  border: 0.5px solid $rev-gray-2;
  border-radius: 2px;
  font-size: 14px;
}

.register-input {
  border-color: transparent;
  background-color: $rev-gray;
  color: $black;
  font-weight: 400;
  padding-left: 7px;
  resize: none;
  width: 100%;
  border-radius: 0.25rem;
  padding: 8px;

  &[readonly],
  &[disabled] {
    color: $gray2;
  }
}

.hours-input {
  width: 60px;
  padding-left: 0;
  padding-right: 0;
}

.accordian-indent {
  padding-left: 44px;
}

/*-------------begin company_cmpetitors.html styles-----------*/

.competitor-logo-wrapper {
  width: 125px;
}

/*--------company.html styles--------*/

.chartAreaWrapper {
  width: 100%;
  overflow-x: scroll;
}

.logo-banner {
  height: 200px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  @media (min-width: $medium) {
    height: 300px;
  }

  .banner-img {
    border-style: none;
    margin: auto;
    max-width: 80%;
    max-height: 80%;
    padding: 30px;

    @media (min-width: $small) {
      max-height: 100%;
    }
  }
}

.banner-text {
  color: #ffffff;
  font-size: 50px;
  font-weight: 600;
  text-align: center;
}

.row-count {
  color: $black-50;
  font-weight: 300;
  text-align: center;
}

.saved-text {
  color: $gray2;
  font-weight: 300;
  font-size: 14px;
}

.company {
  background-color: white;
  padding: 1rem;

  @media (min-width: $small) {
    padding: 2rem;
  }

  .title-column {
    display: flex;
    margin-bottom: 10px;

    @media (min-width: $medium) {
      justify-content: flex-end;
      margin-bottom: 0;
    }
  }

  .company-name {
    font-size: 36px;
    line-height: 1;
  }

  .linkbutton {
    background-color: #f0f0f0;
    font-weight: 500;
    font-family: inherit;
    text-transform: initial;
    letter-spacing: initial;
    white-space: nowrap;
    &:hover {
      background-color: #d0d0d0;
    }
  }

  .company-title {
    font-size: 24px !important;
    line-height: 1.2;
  }

  .company-section {
    border-radius: $border-radius;
  }


  .linkbutton:last-of-type {
    margin-right: 0;
  }
}

// DEFAULT MARGINS ARE .39"
// # Size of a standard letterhead.
// 1280,1696
// @page {
//     // size: Letter;
//     size: 8.5in 11in;
// }

.user-picture {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.break-word {
  overflow-wrap: break-word;
}

.hub-label {
  color: $gray2;
}

.google-color {
  width: 14px;
  height: 14px;
  margin: auto;
  margin-right: 8px;
}

.fa-google {
  color: #d22a1f;
}

.metric-name {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.125rem;
  height: 2.5rem;
  min-width: 6.25rem;
}

.sub-description {
  font-size: 0.85rem;
  font-weight: 400;
  color: $gray2;
}

.metric-row {
  height: 2.5rem;
}

.graph-header {
  display: flex;
  justify-content: space-between;
}

.social-media {
  display: flex;

  .social-wrapper {
    display: flex;
    margin: auto;
  }

  .logo-icon-container {
    border-radius: 3px;
    width: 65px;
  }

  .logo-icon {
    width: 100%;
  }
}

.resend-button {
  background-color: $gray1;
  color: white;

  @media (min-width: $medium) {
    width: initial;
  }

  &:hover,
  &:focus {
    background-color: $gray2-dark;
    border-color: transparent;
    color: white;
  }
}

/*--------end company.html styles--------*/

/*--------begin company_edit.html styles--------*/

.form-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.5rem;
  margin-bottom: 10px;

  .title {
    color: $gray2;
    font-size: 13px;
    font-weight: 500;
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    .btn {
      &:not(:last-child) {
        margin-right: 0.5rem;
      }
      @media (max-width: $medium) {
        padding: 0.65rem 1rem;
        width: 50%;
        &.full {
          width: 100%;
        }
      }
    }
  }
}

.apply-login-align {
  justify-content: center !important;

  @media (min-width: 1560px) {
    justify-content: flex-end !important;
  }

  @media (max-width: 1200px) and (min-width: 780px) {
    justify-content: flex-end !important;
  }
}

.service-checkbox {
  position: absolute;
  opacity: 0;
}

.container-public {
  max-width: 1140px !important;
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  @media (min-width: $small) {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }
}

.container-medium {
  max-width: 960px !important;
}

.container-small {
  max-width: 648px;
}

.form-container {
  background-color: white;
  border-radius: $border-radius-xxl;
  padding: 1rem;
  margin-bottom: 2rem;

  @media (min-width: $small) {
    padding: 1.875rem;
  }

  .section-header {
    color: #1d2328;
    font-size: 22px;
    line-height: 1;
  }

  .input {
    opacity: 0;
  }

  .link-input-wrapper {
    position: relative;
    margin-bottom: 0px;
  }

  .link-input {
    padding-left: 35px;
    margin-bottom: 5px;
    position: relative;
  }

  .link-icon-wrapper {
    width: 40px;
    height: 47px;
    position: absolute;
    top: 28px;
    display: flex;
  }

  .fa-icon {
    margin: auto;
  }

  .hub-wrappers {
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;

    .hub-wrapper {
      display: flex;
      margin-left: auto;
    }

    .hub-info {
      padding-left: 0;
      padding-right: 10px;
      width: 100%;
    }
  }

  .hub-icon {
    width: 21px;
    height: 21px;
    margin: auto;
  }

  .hub-disabled {
    opacity: 0.3;
  }

  #input_img {
    position: absolute;
    bottom: 2px;
    left: 5px;
    width: 24px;
    height: 24px;
  }

  .primary-col {
    @media (min-width: $large) {
      text-align: center;
    }
  }

  .regular-checkbox {
    -webkit-appearance: none;
    border: 1px solid #b7bdc2;
    padding: 7px;
    margin-top: 6px;
    display: inline-block;
    position: absolute;
    opacity: 0;
  }
  .visually-hidden {
    opacity: 0;
  }

  .regular-checkbox:active,
  .regular-checkbox:checked:active {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px 1px 3px rgba(0, 0, 0, 0.1);
  }

  .regular-checkbox:checked {
    background-color: #f7f7f7;
    border: 1px solid #b7bdc2;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
      inset 15px 10px -12px rgba(255, 255, 255, 0.1);
    color: #99a1a7;
  }

  .regular-checkbox:checked:after {
    content: '\2714';
    font-size: 12px;
    position: absolute;
    top: 0.15rem;
    left: 0.2rem;
    color: #99a1a7;
    font-weight: 100;
    line-height: 1;
  }
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}

.kpi-row {
  margin-bottom: 15px;
}

.data-button {
  padding: 0.23rem 0.75rem;
  min-width: 0;
  width: 100%;
}

.edit-service-items-button {
  padding: 0.23rem 0.75rem;
  min-width: 0;
}

.service-item-group {
  font-weight: 500;
  font-size: 1rem;
  color: $black;
}

.fa-trash-alt {
  margin: auto 0;
  color: $black-50;
  cursor: pointer;

  &:hover {
    color: $red;
  }
}

/*--------end company.edit.html styles--------*/

.mce-panel {
  border: 0px #ffffff !important;
}

.main-content {
  background-color: white;
  border: 0.5px solid #dddddd;
  border-radius: 2px;
  padding: 10px;
}

.navbar-inverse .navbar-nav > .active > a,
.navbar-inverse .navbar-nav > .active > a:hover,
.navbar-inverse .navbar-nav > .active > a:focus {
  background-color: initial;
  opacity: 1;
}

.navbar-inverse .navbar-nav > li > a {
  color: #ffffff;
  opacity: 0.7;
}

@media (max-width: $medium) {
  .navbar-account {
    display: none;
  }
}

.lean-canvas {
  border-radius: 4px;

  .col {
    border: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    margin: -1px 0;
    min-width: 100%;

    @media (min-width: $large) {
      margin: -1px;
      min-width: 20%;
    }
  }

  .label {
    background-color: $simply-gray;
    min-height: 90px;
    padding: 10px;
  }

  .sub-step {
    border-top: 0;

    .label {
      background-color: white;
    }
  }

  .number {
    color: $gray1;
    font-size: 3em;
    margin-right: 10px;
    line-height: 0.75em;
  }

  .title {
    color: $blue;
    font-weight: bold;
    line-height: 1.2em;
  }

  .canvas-subtitle {
    color: $blue;
    font-size: 0.75em;
    font-weight: bold;
    margin-top: 20px;
  }

  .description {
    font-size: 0.8em;
    line-height: 1.2em;
  }

  .text-input {
    border: 0;
    flex-grow: 1;
    min-height: 150px;
    padding: 10px;
    outline-width: 0;
    font-size: 15px;
    //font-family: Verdana, Arial, Helvetica, sans-serif;
    overflow-y: hidden;

    &:hover,
    &:focus {
      overflow-y: auto;
    }

    &[readonly],
    &[disabled] {
      color: $gray2-dark;
      /* autoprefixer: off */
      -webkit-text-fill-color: $gray2-dark;
      -webkit-opacity: 1;
      opacity: 1;
      /* autoprefixer: on */
    }
  }
}

.bg-blue {
  background-color: $blue !important;
}

.bg-light-blue {
  background-color: $light-blue !important;
}

.application-header {
  display: flex;
  background-color: white;
  align-items: center;
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 1rem;

  .step-progress-outer {
    display: block;
    width: 100%;
    background-color: #ddd;
    height: 10px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    margin-right: 10px;

    &:last-of-type {
      margin-right: 0;
    }

    @media (min-width: $medium) {
      max-width: 200px;
    }
  }

  .step-progress-inner {
    background-color: $blue;
    height: 100%;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;

    .btn {
      width: 50%;

      @media (min-width: $medium) {
        width: initial;
      }
    }
  }

  h1 {
    @media (max-width: $medium) {
      font-size: 25px;
    }
  }
}

.dropdown-menu {
  z-index: 1021;
}

.dropdown-item {
  padding: 0.5rem 1rem;
}

// .dropdown-item:active {
//   background-color: $blue;
// }

.dropdown-select-menu {
  width: auto;
  height: auto;
  overflow: auto;
  z-index: 1000;
}

.application-body {
  padding: 0px;
  padding-top: 10px;
  margin: auto;
  margin-bottom: 2rem;

  @media (max-width: $small) {
    padding: 0px;
  }

  &.container-small,
  &.home {
    margin-top: 2rem;
  }

  &.container-small {
    max-width: 650px;
  }

  .step {
    font-weight: 600;
    font-size: 14px;
    color: $gray3;
  }

  .cohort-title {
    font-size: 24px;
    font-weight: 300;
  }
}

.vote-img {
  border-radius: 8px;
  padding: 0px;
}

.vote-score {
  font-weight: 900;
  font-size: 1.25rem;
  margin-top: -0.5rem;
}

.cohort-card {
  .card-body {
    padding: 3rem;
    @media (max-width: $medium) {
      padding: 1rem;
    }
  }
}

.cohorts-titles {
  @media (max-width: $small) {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

.border-top {
  border-top: 1px solid $rev-gray-2 !important;

  @media print {
    border-top: 0px !important;
  }
}

.card {
  overflow: hidden;
}

.card-schedule {
  border: none !important;
  padding: 1.5rem;
  background-color: $simply-gray;
}

.icon-overlay {
  position: absolute;
  right: -4px;
  top: 0px;
  color: $gray3;
  cursor: pointer;
  font-size: 0.875rem;
}

.expand-on-hover {
  transition-duration: 0.3s;
  transition-property: all;

  &:hover,
  &:focus,
  &:not(:disabled):not(.disabled):active:not(.no-grow) {
    transform: scale(1.1);
    color: $gray3-dark;
  }
}

.empty-card {
  border: 2px dashed $rev-gray-2 !important;
  color: $gray3;
}

.blue-background {
  background-color: $blue;
}

.card-img {
  max-width: 80%;
  max-height: 100%;
  border-radius: 0;
}

.file-input-container {
  position: relative;
  overflow: hidden;
  padding: 0.5rem 1rem;
}

.file-input-container input[type='file'] {
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
  filter: alpha(opacity=0);
}

.loading-modal {
  display: none;
  position: fixed;
  z-index: 1000;
  top: 0%;
  left: 0%;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.8) 50% 50% no-repeat;
  font-size: 25px;
  color: $gray2;
  .inner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    .fa-spinner {
      margin-right: 0.5rem;
    }
  }
}

/*--------begin company_career.html styles--------*/

.careers-header {
  padding: 40px;
  @media (min-width: $large) {
    padding: 60px;
  }
  @media (min-width: $x-large) {
    padding: 80px 150px;
  }
}

.blue-gradient {
  background: linear-gradient(to right, $blue-3 0%, $blue-2 50%, $blue 100%);
  color: white;
  position: relative;

  .header {
    font-size: 26px;
    font-weight: 600;
  }
  .link {
    font-size: 24px;
    font-weight: 300;
  }
}

.mobile-only {
  display: block !important;
}

.desktop-only {
  display: block !important;
}

@media (max-width: $large) {
  .desktop-only {
    display: none !important;
  }
}

@media (min-width: $large) {
  .rev-orb {
    position: absolute;
    bottom: -135px;
    right: 50px;
    z-index: 1;
    content: url(https://s3.us-west-2.amazonaws.com/dashboard.revroad.com/images/competition/rr-orb.svg);
  }
  .blue-gradient-background {
    position: relative;
    bottom: 200px;
    z-index: -999;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    background-size: 100% 100%;
    // background-image: url(https://s3.us-west-2.amazonaws.com/dashboard.revroad.com/images/competition/blue-gradient.png);
  }
  .bg-text {
    padding: 30px;
    font-size: 22px;
  }
  .mobile-only {
    display: none !important;
  }
}

.bg-text {
  text-align: left;
}

.card-logo-square {
  width: 155px;
  height: 155px;
  @media (min-width: $small) {
    width: 185px;
    height: 185px;
  }
  padding: 20px;
  overflow: hidden;
  position: relative;
  //border: 0 !important;

  .search-helper {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
  }
}

.card-logo-inset {
  width: 96px;
  height: 96px;
}

.card-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 120px;

  .card-logo {
    width: 135px;
    border-right: 1px solid $simply-gray;

    .image-wrapper {
      width: 80%;
    }
    .tag-float {
      position: absolute !important;
      left: 63px;
      top: -5px;
    }
  }

  .card-content {
    margin-right: 0px !important;
    margin-left: 0px !important;
    display: flex;
    width: 100%;
    min-height: 120px;

    @media (min-width: $medium) {
      padding: 20px 0;
    }

    .title {
      font-size: 24px;
      font-weight: 600;
    }

    .label {
      color: $gray2;
      font-weight: 500;
      font-size: 14px;
    }
    .small-label {
      color: $gray2;
      font-weight: 500;
      font-size: 12px;
    }
    .content {
      font-size: 14px;
    }
  }
}

/*--------end company_career.html styles--------*/

.long-and-truncated {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.faq-container {
  width: 100%;
  max-width: 960px;
}

.faq {
  padding: 1.5rem 0 0 0;

  & .faq-question {
    padding: 0 0 0 1.5rem;
    font-weight: 600;
  }
  & .fa-light {
    padding: 0 1.5rem 0 0;
  }
  & .faq-answer {
    display: none;
  }

  &.active {
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    background: #edfafc;

    & .faq-question,
    .fa-light {
      padding: 0;
      color: $blue;
    }
    & .faq-answer {
      display: flex;
    }
  }
}

.current-file {
  margin-top: 10px;
  font-weight: 300;
  @media (min-width: $small) {
    margin-top: 5px;
  }
}

.file-buttons {
  width: 100%;
  @media (min-width: $small) {
    width: 160px;
  }
}

.w-responsive {
  width: 100% !important;
  @media (min-width: $medium) {
    width: 75% !important;
  }
  @media (min-width: $x-large) {
    width: 50% !important;
  }
}

.w-fixed-medium {
  width: 300px !important;
}

.full-width-mobile {
  width: 100% !important;
  @media (min-width: $small) {
    width: inherit !important;
  }
}

.full-width-mobile-email-button {
  width: 85%;
  @media (min-width: $small) {
    width: 300px;
  }
}

.no-btn-hover {
  background-color: inherit !important;
  border-color: $blue !important;
  color: $blue !important;
}

.btn-email {
  background-color: #e7e8e9;
  cursor: default !important;

  span {
    user-select: none;
  }

  a {
    user-select: all;
    cursor: pointer;
  }
}

.type-tag {
  margin: auto 0;
  background: #f0f0f0;
  text-transform: uppercase;
  padding: 3px 6px;
  border-radius: 3px;
  font-size: 11px;
  color: #989c9f;
  font-weight: 600;
}

.email-text-area {
  height: 55vh;
}

.forgot-password-container {
  max-width: 650px;
  margin: auto;
  margin-top: 2rem;

  h1 {
    font-size: 48px;
    font-weight: 300;
  }
}

.email-section-header {
  font-size: 14px;
  font-weight: 600;
  color: #8E8E8E;
  margin-bottom: 10px;
}

.email-section-body {
  color: #3a3c4f;
  font-size: 14px;
  font-weight: 600;
}

// #id_email {
//     min-width: 300px;
//     border: 1px solid lightgray;
// }

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.email-wrapper {
  #id_email {
    width: 100%;
    background-color: $simply-gray;
    border-color: transparent;
  }
}

.rank-wrapper {
  @media (min-width: $medium) {
    margin-right: 1rem;
  }
}

.table thead th {
  vertical-align: middle !important;
}

#password-error {
  margin-bottom: 10px;
  color: red;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}

.first-column {
  min-width: 100px;
}

.second-column {
  min-width: 100px;
  text-align: -webkit-right;
}

.cohort-dates {
  margin-top: -6px;
  margin-bottom: 5px;
  color: #a4a6a9;

  @media (min-width: $medium) {
    margin-top: 0px;
  }
}

.cohort-secondary-row {
  width: 100%;
  height: 100%;
  background-color: #fffdfd;
  padding: 8px 20px;
}

.cohort-subtitle {
  padding-top: 20px;
  font-size: 18pt;
  color: $blue;
  font-weight: 400;
  text-decoration: none;
  line-height: 1.2;
}

.profile-text {
  font-size: 38px;
  font-weight: 200;
  line-height: 1;
  margin-left: 20px;

  @media (min-width: $small) {
    font-size: 38px;
  }
  @media (min-width: $medium) {
    font-size: 42px;
  }
  @media (min-width: $large) {
    font-size: 48px;
  }
}

.announcement-banner-wrapper {
  padding: 60px 50px 60px 50px;
  background-image: linear-gradient(90deg, #ef3d37, #f22992);
  width: 100%;
  .competition-text {
    color: #ffffff;
    font-size: 20px;
    font-weight: 400;

    .competition-text-header {
      font-weight: bold;
      font-size: 24px;
    }
  }
}

.comp-logo {
  width: 280px;

  @media (min-width: $small) {
    width: 320px;
  }
}

.vote-banner-wrapper {
  background-image: url('https://s3-us-west-2.amazonaws.com/dashboard.revroad.com/images/competition/ecomp-bg-pattern.jpg');
  padding: 40px 50px 40px 50px;
  color: black;
  width: 100%;
}

.vote-registration-container {
  font-weight: 500;
  font-size: 18px;

  h1 {
    font-size: 36px;
    @media (min-width: $small) {
      font-size: 44px;
    }
  }

  .competition-description-text {
    margin-left: auto;
    margin-right: auto;

    @media (min-width: $small) {
      width: 480px;
    }
  }
  .subscribe-text {
    font-weight: 300;
    font-size: 17px;
  }
}

.competition-banner-wrapper {
  // background-image: url('https://s3-us-west-2.amazonaws.com/dashboard.revroad.com/images/competition/black-background.jpg');
  // background-image: url('https://s3-us-west-2.amazonaws.com/dashboard.revroad.com/images/competition/dust-background.jpg');
  background-image: url('https://cdn.revroad.com/images/ecomp/ecomp-black-wave-darker-2.jpg');
  background-position: center 30%;
  // background-repeat: no-repeat;
  background-size: cover;
  color: black;
  position: relative;

  .left-hand {
    position: relative;
    @media (max-width: $medium) {
      margin-bottom: 50px;
    }
  }

  .sponsor-image {
    // position: relative;
    // width: 100%;
    max-width: 200px;
    width: auto;
    max-height: 120px;
    height: auto;
  }

  .competition-text {
    color: white;
    font-size: 18px;
    font-weight: 400;

    .competition-text-header {
      font-weight: bold;
      font-size: 18px;
    }
  }
  .circle-wrapper {
    // background-image: url('https://s3-us-west-2.amazonaws.com/dashboard.revroad.com/images/competition/circles-graphic.png');
    background-image: url('https://s3-us-west-2.amazonaws.com/dashboard.revroad.com/images/competition/star-boot-shoe.png');
    background-position: right bottom;
    background-size: cover;
    position: absolute;
    //border: 2px solid red;
    bottom: 0px;
    right: 0px;
    width: 100%;
    height: 100%;

    @media (max-width: $medium) {
      height: 300px !important;
      width: 110% !important;
    }
    @media (max-width: $small) {
      width: 110% !important;
    }

    max-width: 1000px;
  }
}

.comp-header {
  font-size: 2rem;
  font-weight: 800;
}

.competition-description {
  font-weight: 400;
  font-size: 16px;

  h1 {
    font-size: 36px;
    color: $black;
  }

  .competition-description-text {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}

.competition-images {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0px;

  .competition-image-wrapper {
    padding: 0px;
    background-position: center center;
    background-size: cover;
    height: 400px;

    @media (max-width: $large) {
      height: 300px;
    }
    @media (max-width: $small) {
      height: 200px;
    }
  }
}

.bottom-banner {
  position: absolute;
  top: -9999px;
  bottom: -9999px;
  left: -9999px;
  right: -9900px;
  margin: auto;
}

.contact-button {
  font-size: 25px;
}

.judge-container {
  padding-top: 40px;
  padding-bottom: 100px;

  .judge-header {
    color: $blue;
    font-weight: bold;
    padding-top: 15px;
  }
}

.black-background {
  background: black !important;
}

.light-gray-background {
  background: $body-background !important;
}

.competition-info {
  background-color: white;
  color: $black;
  text-align: justify;
  font-weight: 300;

  h1 {
    font-size: 36px;
  }

  .competition-info-header {
    color: $blue;
    font-size: 20px;
    font-weight: bold;
    padding-top: 10px;
  }
  .competition-info-text {
    font-size: 16px;
    line-height: 1.4em;
    a {
      color: $blue;
    }
  }
  li {
    font-size: 16px;
    line-height: 1.4em;
  }
  ul {
    margin-left: -20px;
  }
}

.banner-header {
  h1 {
    color: #ffffff;
    font-size: 1.5rem;
    line-height: 1;
    margin: 0;
    padding: 0;
    @media (min-width: $medium) {
      font-size: 2.5rem;
    }
  }

  background-position: center center;
  background-size: cover;
  height: 200px;
  border-radius: 1rem;
  overflow: hidden;
  margin-top: 1.5rem;

  @media (min-width: $medium) {
    height: 300px;
  }

  &.partner-image {
    background-image: url(https://cdn.revroad.com/images/headers/partners-header.jpg);
  }
  &.talent-pool-image {
    background-image: url(https://cdn.revroad.com/images/footers/talent-pool.jpeg);
  }
  &.homepage-image {
    background-image: url(https://s3.us-west-2.amazonaws.com/dashboard.revroad.com/images/footer-judges-blue.jpg);
  }
}

.info-hide {
  @media (max-width: $small) {
    display: none !important;
  }
}

.info-hide-medium {
  @media (max-width: $medium) {
    display: none !important;
  }
}

.profile-image-small {
  cursor: pointer;
  width: 38px;
  height: 38px;
  border-radius: 50%;
}

.profile-image {
  width: 90px;
  height: 90px;
  border-radius: 50%;
}

.profile-image-list {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-top: 0px;

  @media (min-width: $medium) {
    width: 90px;
    height: 90px;
    padding: 0px;
  }
}

.directory-wrapper {
  height: 100%;
  align-content: center;
}

.button-mobile {
  @media (max-width: $medium) {
    width: 33.33%;
  }
}

.application-filters-container {
  max-width: 100%;

  h1 {
    font-size: 30px;
  }
  h2 {
    font-size: 22px;
  }
  .filters-wrapper {
    width: 100%;
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    flex-wrap: wrap;
    @media (min-width: $medium) {
      flex-wrap: nowrap;
    }
  }
}

select {
  padding: 10px;
}

.analyst_form {
  background-color: #ffffff;
  padding-left: 7px;
  box-shadow: none;
  border: 1px solid $rev-gray-2;
  width: 100%;
  margin-right: 10px;
  margin-top: 0.5rem;
  font-weight: 300;
  width: 100%;
}

.analyst_form_selected {
  appearance: none;
  background: none !important;
  border: none;
  padding: 0 !important;
  color: $blue !important;
  cursor: pointer;
  font-weight: 300;
  width: 100%;

  &:focus-visible {
    outline: none;
  }
  &.no-analyst {
    color: $red !important;
  }
}

.analyst_form_disabled {
  appearance: none;
  background: none !important;
  border: none;
  color: $black !important;
  padding: 0 !important;
  font-weight: 300;
  width: 100%;
}

.priority-select-container {
  height: 12px;
  width: 12px;
  border-radius: 6px;
  background-color: #d8d9d8;
  margin-left: 0.5rem;
}

.invisible-input-container {
  overflow: hidden;
  cursor: pointer;
  position: relative;
}

.invisible-venture-input {
  display: none;
  width: 120px;
}

.invisible-input {
  opacity: 0;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  position: absolute;
}

.morecontent span {
  display: none;
}

.user-circle {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*-------------vote.html styles---------------*/

.vote-subtitle {
  color: #c1c1c1;
  font-size: 18px;
  font-weight: 400;
}

.vote-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.25rem;
  padding-top: 1rem;
  padding-bottom: 1.5rem;
  justify-content: space-between;
  font-weight: 300;
  width: 100%;
}

.comp-company-logo-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  // below might be temporary once we get actual logos
  width: 112px;
  height: 112px;
  border-radius: 14px;
  min-width: 112px;

  @media (max-width: $medium) {
    width: 80px;
    height: 80px;
    border-radius: 10px;
    min-width: 80px;
  }
}

.comp-company-logo-wrapper-small {
  width: 80px;
  height: 80px;
  border-radius: 10px;
  min-width: 80px;
}

.vote-logo-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  // below might be temporary once we get actual logos
  width: 80px;
  height: 80px;
  border-radius: 8px;
  min-width: 80px;
}

.judge-picture-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 8px;
  min-width: 80px;
}

.rank-button {
  .btn {
    padding: 0.4rem 1rem !important;
    min-width: 0;
  }
}

.vote-button-mobile {
  width: 130px !important;
  height: 30px;
  padding: 0 0.75rem;
  font-size: 13px;
}

.cohort-founders {
  padding: 0;
  padding-bottom: 0.5rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  color: $gray2;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

/*-------------end vote.html styles-----------*/

.cohort-action-label {
  line-height: 20px;
  width: 78px;
  font-size: 13px;
  @media (min-width: $small) {
    font-size: initial;
    width: 115px;
  }
}

.override-border {
  border: 1px !important;
}

.rectangle-progress {
  margin-right: 2px;
  margin-left: 2px;
  height: 10px;
  width: 127px;
  background-color: #f4f4f4;
  // margin-right: 2px;
}

.progress-card {
  border: 1px solid #f2f2f2;
  // min-width: 32%;
  // max-width: 32%;
  // padding-bottom: 10%;
  // height: 250px;
  // padding: 0.5%;
  position: relative;
  // max-height: 250px;
  // margin: 2px;

  .top-row {
    background-color: #f4f4f4;
    border: 1px solid #f2f2f2;
    padding: 10px;
    display: flex;
    align-items: center;
    min-height: 52px;
  }
  .scroll {
    overflow-y: scroll;
    @media print {
      overflow: hidden;
    }
    @media (max-width: $medium) {
      height: 150px;
    }
    @media (max-width: $small) {
      height: 85px;
    }
    height: 200px;
  }
  .scroll-short {
    overflow-y: hidden;
    height: 60px;
    @media print {
      height: initial;
      overflow: hidden;
    }
    &:hover {
      overflow-y: scroll;
    }
  }
}

.empty-progress-card {
  // margin: 0.5%;
  // min-width: 32%;
  // max-width: 32%;
  opacity: 0.4;
  // display: flex;
  // flex-wrap: wrap;
  background-color: #f4f4f4;
  border: 1px solid #f2f2f2;
  padding: 10px;
  min-height: 52px;
  position: relative;
  // margin-bottom: 8px;
}

#progress-bar {
  @media (max-width: $large) {
    display: none;
  }
}

.container-header {
  font-size: 25px;
  font-weight: 300;
}

.row-header {
  font-size: 22px;
  font-weight: 200;
}

.semi_transparent {
  opacity: 0.5;
}

.tooltip {
  position: relative;
  // border-bottom: 1px dotted black;
  opacity: 1;
  z-index: 1151 !important;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  min-width: 200px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  // z-index: 0;
  bottom: 125%;
  left: 50%;
  margin-left: -100px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-inner {
  max-width: 100% !important;
  text-align: left;
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.tooltip-bottom {
  position: relative;
  // border-bottom: 1px dotted black;
  opacity: 1;
  z-index: 1;
  display: inline-block;
}

.tooltip-bottom .tooltiptext-bottom {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue',
    Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  visibility: hidden;
  min-width: 420px;
  background-color: #555;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  // z-index: 0;
  top: 125%;
  left: 50%;
  margin-left: -210px;
  opacity: 0;
  transition: opacity 0.3s;
  font-weight: 400;
  line-height: 14px;
}

.tooltip-bottom .tooltiptext-bottom::after {
  content: ' ';
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #555 transparent;
}

.tooltip-bottom:hover .tooltiptext-bottom {
  visibility: visible;
  opacity: 1;
}

.page-break-after {
  page-break-after: always;
}

.avoid-page-break-inside {
  page-break-inside: avoid;
}

/*-------------cron_logs.html styles---------------*/

.logs {
  max-height: 400px;
  overflow-y: scroll;
  white-space: pre-wrap;
}

/*------------- end vote.html styles---------------*/

/*------------- start split-divider.html styles---------------*/
.divider {
  border-bottom: $rev-gray-2 2px dashed;
  margin-bottom: 10px;
  width: 45%;
}
.text {
  width: 15%;
}

/*------------- start end-divider.html styles---------------*/

.drop-file {
  min-height: 200px;
  display: flex;
  background-color: $simply-gray;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.regular-text {
  text-transform: none !important;
}

.input-fill-width {
  input {
    width: 100%;
  }
}

.label-text {
  color: $gray2;
  font-weight: 500;
  font-size: 14px;
}
.goals-page {
  .logo-banner {
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #202020;
  }
  #goals-22 {
    background-image: url(https://s3.us-west-2.amazonaws.com/dashboard.revroad.com/images/RevRoad-2022-Goals.svg);
  }
  #goals-23 {
    display: flex;
    background-color: rgb(0, 42, 52);
    max-width: 100%;
    height: unset;
    .logo-banner {
      background: none;
      height: unset;
      max-width: 100%;
    }
    .logo-banner-img {
      height: inherit;
    }
    .tileable {
      overflow: hidden;
      background-image: url(https://s3.us-west-2.amazonaws.com/dashboard.revroad.com/images/RevRoad-2023-Goals-Tileable.svg);
      background-size: auto 100%;
      background-repeat: repeat-x;
      flex: 1;
      &:first-child {
        transform: scaleX(-1);
      }
    }
  }
  .goal-description {
    max-width: 228px;
    margin: auto;
    font-size: 16px;
    color: $gray2;
  }
  .goal-meta {
    font-size: 14px;
    color: $gray2;
  }
  .goal-chart-target-text {
    // color: $blue;
    font-size: 12px;
  }
  .coin {
    width: 50px;
  }
  .square-number {
    width: 40px;
    margin-left: -2px;
  }
}

.announcement-banner {
  position: absolute;
  background-image: linear-gradient(90deg, #ef3d37e0, #f22992e0);
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 12px;
  font-weight: 600;
  &:hover {
    background-image: linear-gradient(90deg, #ef3d37, #f22992);
  }
}

.news-img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  // border-color: $black !important;

}

.no-border {
  border: None !important;
}

.truncate-3-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

$drawer-width: 370px;
$drawer-transition: margin 0.25s;

@mixin chevron-icon {
  .fal {
    color: $blue;
    transform: none;
    &:before {
      content: '\f053';
    }
  }
}

@mixin menu-icon {
  .fal {
    color: $blue;
    transform: scaleX(-1);
    &:before {
      content: '\f0ca';
    }
  }
}

// INITIAL STATES
#lesson {
  //
  overflow: hidden;
  background-color: white;
  min-height: calc(100vh - $navbar-height-sm);
  @media (min-width: $medium) {
    min-height: calc(100vh - $navbar-height-lg);
  }
  #lesson-navigation-sidebar {
    position: fixed;
    z-index: 100;
    transition: $drawer-transition;
    margin-left: -100%;
    height: calc(100vh - $navbar-height-sm);
    width: 100%;
    background-color: $simply-gray;
    @media (min-width: $medium) {
      height: calc(100vh - $navbar-height-lg);
      margin-left: 0;
      width: $drawer-width;
    }
    #lesson-navigation-sidebar-content {
      overflow-y: scroll;
    }
  }
  #lesson-wrapper {
    position: relative;
    overflow-x: hidden;
    transition: $drawer-transition;
    background-color: white;
    flex: 1;
    @media (min-width: $medium) {
      margin-left: $drawer-width;
    }
  }
  #lesson-content {
    padding-bottom: 5rem;
    @media (min-width: $medium) {
      padding-left: 1.875rem;
      padding-right: 1.875rem;
    }
  }
  #lesson-navigation {
    border-top: 1pt solid $rev-gray;
    width: 100%;
    position: absolute;
    bottom: 0;
    background-color: white;
    &.complete {
      background-color: transparentize($color: $blue, $amount: 0.9);
      button {
        background-color: $blue;
      }
    }
    .nav-arrow-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3em;
      height: 3em;
      background-color: white;
      border-radius: 100%;
      border: 1pt solid $blue;
      .fal {
        color: $blue;
      }
      cursor: pointer;
      &:hover {
        background-color: $simply-gray;
      }
    }
  }
  #toggle-sidebar-button {
    border-radius: 100%;
    background-color: white;
    width: 2rem;
    height: 2rem;
    border: 1pt solid $blue;
    cursor: pointer;
    position: absolute;
    right: calc(-100% + 26px);
    top: 12px;
    z-index: 101;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: $medium) {
      @include menu-icon;
    }
    @media (min-width: $medium) {
      // right: calc(-2rem - 12px);
      right: -1rem;
      @include chevron-icon;
    }
  }
}

// MOBILE OPEN STATE, ONLY APPLY TO MOBILE
#lesson.mobile-sidebar-open {
  @media (max-width: $medium) {
    overflow: hidden;
    #lesson-navigation-sidebar {
      margin-left: 0;
      width: 100%;
    }
    #lesson-wrapper {
      margin-left: 100%;
      height: calc(100vh - 70px);
    }
    #toggle-sidebar-button {
      right: 30px;
      @include chevron-icon;
    }
  }
}

// DESKTOP CLOSED STATE, ONLY APPLY TO DESKTOP
#lesson.desktop-sidebar-closed {
  @media (min-width: $medium) {
    #lesson-navigation-sidebar {
      margin-left: -$drawer-width;
    }
    #lesson-wrapper {
      margin-left: 0;
    }
    #toggle-sidebar-button {
      right: calc(-2rem - 30px);
      @include menu-icon;
    }
  }
}

.page-title {
  font-family: $body-font;
  font-weight: 600;
  font-size: 1.5rem;
}

.course-progress-item {
  &.active {
    color: $blue;
    font-weight: 500;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
}

#lesson-navigation {
  .section {
    font-size: 1rem;
    color: $gray2;
    text-transform: uppercase;
  }
  .resource-title {
    font-size: 1rem;
    font-weight: 600;
  }
}

.no-decoration {
  text-decoration: none;
  color: inherit;
  &:hover {
    color: inherit;
  }
}

.sort-control {
  line-height: 0.25;
  font-size: 1.5rem;
  color: $blue;

  .fal {
    line-height: inherit;
    cursor: pointer;
    &:hover {
      color: $blue-3 !important;
      font-size: 1.55rem;
    }
  }
}

.circle-icon-containter {
  height: 24px;
  width: 24px;
  background-color: white;
  border-radius: 100%;
  margin-right: 15px;
}

.circle-icon {
  color: rgba(0, 0, 0, 0.8);
  font-size: 12px;
}

.footer-logo {
  width: 140px;
}
.footer-logo-wrapper {
  padding-bottom: 20px;
}

.copyright {
  color: #ffffff50;
}

.new-stay-informed-email {
  padding: 0px;
  width: auto;
}

.btn-subscribe {
  height: 50px;
  color: #e9e7e8;
  background-color: #3e3e3e;
  border-radius: 0;
  border-color: #767576;
  border-width: 1px;
  border-left: 0px;
  font-size: 13px;
  padding: 6px 24px;
  width: auto;
  transform: none !important;
}

.btn-ecomp-banner {
  width: 300px;
  // width: 100%;
  @media (min-width: $small) {
    width: 175px;
  }
}

.subscribe-input {
  background: transparent;
  color: #ffffff;
  border: solid;
  border-color: #767576;
  height: 50px;
  width: auto;
  border-width: 1px;
  padding: 6px 10px;
}

.subscribe-confirmation {
  color: #ffffff;
  background-color: #4cd5a7;
  border: 0;
}

hr {
  &.marketing {
    margin: 31px 0;
  }
  &.dotted {
    border-top: 1.5px dotted $black;
    background-color: transparent;
    opacity: 0.4;
  }
}

.subscribe-confirmation-container {
  color: #ffffff;
  background-color: #4cd5a7;
  border: 0;
  padding: 15px;
  margin-bottom: 24px;
}

.items_box {
  background-color: #f7f7f7;
  padding: 20px 30px 10px;
  margin-bottom: 20px;
  color: #3a3c4f;
  font-size: 14px;

  font-weight: 600;
}

.blue-title {
  font-size: 22px;
  color: #00b1db;
  font-weight: 600;
  margin-top: 0;
}

.offer-container {
  margin: 0 auto;
}

.hover-underline {
  &:hover {
    text-decoration: underline;
  }
}

.index-page {
  h1 {
    font-size: 26px;

    @media (min-width: $small) {
      font-size: 36px;
    }
  }

  .overview-container {
    padding: 0 36px;
    @media (min-width: $small) {
      padding: 0 6rem;
    }
  }

  .nav-tabs {
    border: none;

    .nav-item button {
      width: 100%;
    }

    .nav-link {
      padding: 0 0 0.25rem;
      text-align: left;
      transition: none;
      color: $gray3;
      border-width: 0 0 3px 0;
      border-style: solid;
      border-bottom-color: $rev-gray;
      font-size: 1.5rem;
      font-weight: 500;
    }

    .nav-link.active {
      color: $black !important;
      border-image: linear-gradient(to right, $blue, #b2e7f4) 1;
    }
  }

  .accordion-header h2 {
    margin-bottom: 0.25rem;
  }

  .accordion-body {
    padding-top: 0;
  }
}

.ecomp-banner {
  padding: 2rem;
  aspect-ratio: 3.7;
}
@media (max-width: $medium) {
  .ecomp-banner {
    padding: 0;
    aspect-ratio: 1.4;
  }
}

.welcome-image,
.year-image,
.season-image {
  position: absolute;
  left: 1rem;
  width: calc(100% - 2rem);
}

.for-desktop {
  display: none;
}
.for-mobile {
  display: block;
}
@media (min-width: $medium) {
  .for-desktop {
    display: block;
  }
  .for-mobile {
    display: none;
  }
}

// .pc-dashboard-container {

//   .img {
//     max-width: 95px;
//     max-height: 95px;
//   }

// }

.avatar {
  display: flex;
  width: 96px;
  height: 96px;
  border: 1px solid $border-color;
  border-radius: 0.75rem;
  @media (max-width: $small) {
    width: 64px;
    height: 64px;
  }
}

.rr-card {
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06),
    0px 1px 3px 0px rgba(0, 0, 0, 0.1);

  margin-bottom: 1.5rem;
  border-radius: $border-radius;
  overflow: hidden;
  .top-row {
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    gap: 1.25rem;
    @media (min-width: $medium) {
      flex-direction: row;
    }
    // .border-bottom {
    //   border-bottom: 1px solid #e5e7eb;
    // }
    .title {
      text-decoration: none;
      font-size: 1.5rem;
      font-weight: 700;
      vertical-align: top;
      color: $black;
      line-height: 1.2;
      @media (max-width: $small) {
        font-size: 1.125rem;
      }
    }
    .small-title {
      text-decoration: none;
      font-size: 1.25rem;
      font-weight: 700;
      vertical-align: top;
      color: $black;
      line-height: 1.2;
      @media (max-width: $small) {
        font-size: 1rem;
      }
    }
    .smaller-text {
      font-size: 0.875rem;
      font-weight: 500;
      color: $light-black;
    }
  }

  .negative-margin-top-sm {
    margin-top: -0.25rem;
  }
  .bottom-row {
    background-color: #f9fafb;
    border-top: 1px solid #e5e7eb;
    @media (min-width: $medium) {
      padding: 1.25rem;
    }

    .single-data-wrapper + .single-data-wrapper {
      border-top: 1px solid #e5e7eb;
      // @media (min-width: $medium) {
      //   border-top: 0;
      // }
    }
    .single-data-wrapper {
      display: flex;
      gap: 0.4375rem;
      justify-content: center;
      text-align: center;
      padding: 1.25rem;

      .text-label {
        font-size: 0.875rem;
        font-weight: 500;
        color: $black-80;
      }
      .text-data {
        font-size: 0.875rem;
        font-weight: 600;
        // @media (min-width: $medium) {
        //   font-size: 1.875rem;
        // }
      }
    }

    .data-wrapper + .data-wrapper {
      border-top: 1px solid #e5e7eb;
      @media (min-width: $medium) {
        border-top: 0;
      }
    }
    .data-wrapper {
      display: flex;
      gap: 0.4375rem;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 1.25rem;
      @media (min-width: $medium) {
        display: block;
        padding: 0;
      }
      .text-label {
        font-size: 0.875rem;
        font-weight: 500;
        color: $black-80;
      }
      .text-data {
        font-size: 0.875rem;
        font-weight: 600;
        line-height: 1.2;
        @media (min-width: $medium) {
          font-size: 1.5rem;
        }
      }
    }
  }
}

.gap-2-5 {
  gap: 0.75rem;
}

.dsp-card {
  background: #fff;
  border-radius: $border-radius;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06),
    0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;

  .border-top {
    border-top: 1px solid $border-color !important;
  }

  .top-row {
    display: flex;
    flex-direction: row;
    padding: 1.5rem;
    @media (min-width: $small) {
      padding: 2.5rem;
    }
    .title {
      text-decoration: none;
      font-size: 1.5rem;
      font-weight: 700;
      vertical-align: top;
      color: $black;
      line-height: 1.2;
    }
    .sub-title {
      text-decoration: none;
      font-size: 1.25rem;
      font-weight: 600;
      vertical-align: top;
      color: $black;
      line-height: 1.2;
    }
    .head-title {
      font-size: 1rem;
      font-weight: 600;
      color: $gray2;
      text-transform: uppercase;
      margin-bottom: 1rem;
    }
    .exclude-icon {
      width: 0.25rem;
    }
  }

  .bottom-row {
    background-color: #f9fafb;
    @media (min-width: $medium) {
      padding: 1.25rem;
    }
    .data-wrapper + .data-wrapper {
      border-top: 1px solid #e5e7eb;
      @media (min-width: $medium) {
        border-top: 0;
      }
    }
    .data-wrapper {
      display: flex;
      gap: 0.4375rem;
      justify-content: center;
      text-align: center;
      padding: 1.25rem;
      @media (min-width: $medium) {
        display: block;
        padding: 0;
      }
      .text-label {
        font-size: 0.875rem;
        font-weight: 500;
        color: $gray2;
      }
      .text-data {
        font-size: 0.875rem;
        font-weight: 600;
        @media (min-width: $medium) {
          font-size: 1.875rem;
        }
      }
    }
  }

  .negative-margin-right {
    margin-right: -1.5rem;
  }
  .negative-margin-left {
    margin-left: -10px;
  }

  .progress_donut {
    height: 130px;
    width: 130px;
  }

  .accordion-button::after {
    position: absolute;
    left: 0;
    transform: rotate(-90deg) !important;
  }

  .accordion-button:not(.collapsed)::after {
    transform: rotate(0deg) !important;
  }

  .accordion-button:not(.collapsed)::after {
    filter: grayscale(100%);
  }

  .accordion-item {
    border: none;
    margin-bottom: 1rem;
  }

  .accordion-button {
    padding-left: 30px !important;
    background-color: white !important;
    width: auto;
  }
}

.time-record-icon {
  width: 15px;
  height: 15px;
}

/* styling for pagination */
.pagination > li > a,
.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
  color: $blue;
}

.page-item {
  width: 44px;
  text-align: center;
  margin-bottom: 0.25rem;
}

/* styling for autocomplete search */
.ui-menu {
  border: 1px solid #d8d9d8 !important;
  border-top: none !important;
  z-index: 1000 !important;
  font-family: inherit !important;
  .ui-menu-item-wrapper {
    padding: 0.5rem 1rem !important;
  }
  .ui-state-hover,
  .ui-state-active {
    background-color: #e9ecef !important;
    color: #212121 !important;
    border: none !important;
    transform: none !important;
    margin: 0px !important;
  }
}

.disabled-img {
  filter: grayscale(100%);
}

.strikethrough {
  text-decoration: line-through;
  opacity: 0.6;
}

.grid-table {
  display: grid;
  > * {
    padding: 1rem;
  }
  .grid-col-header {
    background-color: #f9fafb;
  }
}
.grid-row-divider {
  grid-column: 1 / -1;
  height: 1px;
  background-color: #e5e7eb;
  padding: 0;
}

.content-wrapper {
  background-color: $body-background;
  border-radius: $border-radius-xxl;
  display: flex;
  justify-content: center;
  padding: 2rem 1rem;
  @media (min-width: $small) {
    padding: 2rem;
  }
  @media (min-width: $medium) {
    padding: 3.5rem;
  }
  .title-banner {
    font-size: clamp(2rem, 7vw, 3.5rem);
    line-height: 1.125;
  }
  .title-img {
    width: clamp(5rem, 7vw, 6rem);
  }
  .large-title {
    text-align: center;
    margin-bottom: 2rem;
    font-size: clamp(2rem, 7vw, 3.5rem);
    line-height: 1.125;
  }
  .apply-title-img {
    width: clamp(3.5rem, 9vw, 5rem);
  }
  .content-wrapper-inner {
    max-width: 546px;
    width: 100%;
  }
  .content-wrapper-inner-large {
    max-width: 710px;
    width: 100%;
  }
}

.file-drop-zone {
  border-radius: 16px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2.5rem;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: #{escape-svg($file-drop-zone-border)};

  &.hover {
    background-color: $light-blue;
  }

  &.is-invalid {
    background-image: #{escape-svg($file-drop-zone-border-invalid)};
  }

  * {
    pointer-events: none;
  }
}

.file-row {
  margin-top: 0.5rem;
  gap: 0.5rem;
  word-break: break-all;

  .btn-primary .fa-light {
    color: white;
  }

  .fa-trash-can {
    cursor: pointer;
  }
}

.dropdown-menu {
  // so the highlighted dropdown-items don't overflow the dropdown-menu slightly
  overflow-x: hidden;
}

.dropdown-menu-select {
  border-radius: 1rem;
  border-color: $border-color;
  &.dropdown-menu-select-with-search {
    padding-top: 0;
  }
  .select-search-wrapper {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    input {
      background-color: unset;
      border: none;
      box-shadow: none;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }
  .dropdown-item {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .selected-item {
    background-color: $light-blue;
    &:active {
      color: $black;
    }
  }
  .highlighted-item,
  .highlighted-item .dropdown-item {
    background-color: $dropdown-link-hover-bg;
  }
}

.footer-links-wrapper {
  grid-template-columns: min-content;
  @media (min-width: $small) {
    grid-template-columns: min-content min-content;
  }
}

.switch-lg {
  transform: scale(1);
  width: 2.5rem !important;
  height: 1.25rem;
  margin-top: 0.15rem;
}

.slider-progress-bar {
  .slider-progress-bar-step {
    background-color: $medium-blue;
  }
  .slider-progress-bar-step-complete {
    background-color: $blue;
  }
}

.modal-title {
  font-size: 1.375rem;
  font-weight: 500;
}

.marketing-site-header {
  margin-top: 1.75rem;
  margin-bottom: 1.5rem;
  font-size: clamp(3rem, 7vw, 5rem);
  text-align: center;
  @media (min-width: $medium) {
    margin-top: 4.75rem;
  }
}

.marketing-site-subheader {
  font-size: clamp(1.125rem, 2vw, 1.5rem);
  margin-bottom: 4.5rem;
  text-align: center;
}

.linkedin-icon {
  color: $linkedin-blue;
}